import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import {
  BenchmarkStatus,
  DataPeriod,
  EvidencePeriodData,
  Gender,
  GenderDisplayedNames,
  GradeLevels,
  Race,
  RaceDisplayedNames,
  School,
} from "../../../../../../store/onboarding/types";
import _ from "lodash";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { getStudentGradeFromEcg } from "../../../../../common/onboarding/second-step/evidence-tab/student-data-table/Helpers";
import { Grade, TimePeriod } from "../../../../../../store/groups/types";
import Select from "react-select";
import BellowCell from "./BelowCell";
import AtOrAboveCell from "./AtOrAboveCell";
import useUserRole from "../../../../../../utils/hooks/useUserRole";
import { getSchools } from "../../../../../../store/onboarding/actions";
import usePDFGenerate from "../../../../../common/pdf-generate/usePDFGenerate";

type OwnProps = {
  dataPeriod?: DataPeriod
};

type Props = OwnProps;

const EquityBenchmarksModalContent: FunctionComponent<Props> = (props) => {
  const { dataPeriod } = props;
  const [selectedGradeKey, setSelectedGradeKey] = useState<Grade>("K");
  const [selectedSchool, setSelectedSchool] = useState();
  const { isDistrictOwner } = useUserRole();
  const dispatch = useDispatch();

  useEffect(() => {
    let tableElement:HTMLElement = document.getElementById('equity-benchmark-table-row')!
    if(selectedSchool || selectedGradeKey) {
      tableElement?.parentElement?.classList?.add('benchmark-modal-pdf-class');
    } else {
       tableElement?.parentElement?.classList?.remove('benchmark-modal-pdf-class');
    }
  },[selectedSchool, selectedGradeKey])

  const selectedGrade = useMemo(() => {
    return GradeLevels.find((gl) => gl.value === selectedGradeKey);
  }, [selectedGradeKey]);

  const handleGradeLevelChange = (value: any) => {
    setSelectedGradeKey(value.value);
  };

  const handleSchoolChange = (value: any) => {
    setSelectedSchool(value.value);
  };

  const selectedEvidenceColumnGroupId = useSelector<
    ApplicationState,
    number | undefined
  >((s) => s.onboarding.selectedEvidenceColumnGroupId);

  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData | undefined
  >((s) => s.onboarding.evidencePeriodData);

  const dataPeriodSchools = useSelector<
    ApplicationState,
    School[]
  >((s) => s.onboarding.dataPeriodSchools);

  const schools = useSelector<
    ApplicationState,
    School[]
  >((s) => s.onboarding.schools);

  useEffect(() => {
    if(isDistrictOwner && !schools.length) {
      dispatch(getSchools());
    }
  },[schools])

  const schoolOptions = useMemo(() => {
    if(dataPeriodSchools.length) {
      return dataPeriodSchools.map((school) => ({
        label: school.name,
        value: school.id
      }))
    } else {
      let schoolArray:any[] = [];
      for (let i = 0; i < evidencePeriodData?.student_rows.length!; i++) {
        const element = evidencePeriodData?.student_rows[i];
        for (let j = 0; j < element?.student?.teachers?.length!; j++) {
          const teacher = element?.student?.teachers![j];
          for (let k = 0; k < teacher?.schools?.length!; k++) {
            const schoolId = teacher?.schools![k];
            if(!schoolArray.some((sc) => sc.value == schoolId)){
              schoolArray.push({
                label: schools.find((scLabel) => scLabel.id == schoolId)?.name,
                value: schoolId
              })
            }
          }
        }
      }
      return schoolArray;
    } 
  }, [dataPeriodSchools]);

  const evidenceColumnGroup = useMemo(() => {
    return selectedEvidenceColumnGroupId
      ? evidencePeriodData?.evidence_column_groups.find(
          (ecg) => ecg.id === selectedEvidenceColumnGroupId
        )
      : undefined;
  }, [selectedEvidenceColumnGroupId, evidencePeriodData, selectedGradeKey]);

  const raceDictionary = useMemo(() => {
    return evidencePeriodData
      ? _.chain(evidencePeriodData.student_rows)
          .map((sr) => ({
            ...sr.student,
            grade:
              sr.student?.grade ||
              sr.grade ||
              getStudentGradeFromEcg(
                evidencePeriodData.evidence_column_groups,
                sr.student.id!
              ),
          }))
          .filter((s) => s.grade === selectedGradeKey && s.teachers!.some((teacher) => teacher.schools?.includes(selectedSchool!)))
          .groupBy((s) => s.race)
          .mapValues((arr) => arr.map((s) => s.id!))
          .value()
      : {};
  }, [evidencePeriodData, selectedGradeKey, selectedSchool]);

  const specialEducationDictionary = useMemo(() => {
    return evidencePeriodData
      ? _.chain(evidencePeriodData.student_rows)
          .map((sr) => ({
            ...sr.student,
            grade:
              sr.student?.grade ||
              sr.grade ||
              getStudentGradeFromEcg(
                evidencePeriodData.evidence_column_groups,
                sr.student.id!
              ),
          }))
          .filter((s) => (s.grade === selectedGradeKey) && (s.special_education === true) && s.teachers!.some((teacher) => teacher.schools?.includes(selectedSchool!)))
          .groupBy((s) => s.special_education)
          .mapValues((arr) => arr.map((s) => s.id!))
          .value()
      : {};
  }, [evidencePeriodData, selectedGradeKey, selectedSchool]);

  const ELLDictionary = useMemo(() => {
    return evidencePeriodData
      ? _.chain(evidencePeriodData.student_rows)
          .map((sr) => ({
            ...sr.student,
            grade:
              sr.student?.grade ||
              sr.grade ||
              getStudentGradeFromEcg(
                evidencePeriodData.evidence_column_groups,
                sr.student.id!
              ),
          }))
          .filter((s) => (s.grade === selectedGradeKey) && (s.ell === true) && s.teachers!.some((teacher) => teacher.schools?.includes(selectedSchool!)))
          .groupBy((s) => s.ell)
          .mapValues((arr) => arr.map((s) => s.id!))
          .value()
      : {};
  }, [evidencePeriodData, selectedGradeKey, selectedSchool]);

  const SLDDictionary = useMemo(() => {
    return evidencePeriodData
      ? _.chain(evidencePeriodData.student_rows)
          .map((sr) => ({
            ...sr.student,
            grade:
              sr.student?.grade ||
              sr.grade ||
              getStudentGradeFromEcg(
                evidencePeriodData.evidence_column_groups,
                sr.student.id!
              ),
          }))
          .filter((s) => (s.grade === selectedGradeKey) && (s.sld === true) && s.teachers!.some((teacher) => teacher.schools?.includes(selectedSchool!)))
          .groupBy((s) => s.sld)
          .mapValues((arr) => arr.map((s) => s.id!))
          .value()
      : {};
  }, [evidencePeriodData, selectedGradeKey, selectedSchool]);

  const genderDictionary = useMemo(() => {
    return evidencePeriodData
      ? _.chain(evidencePeriodData.student_rows)
          .map((sr) => ({
            ...sr.student,
            grade:
              sr.student?.grade ||
              sr.grade ||
              getStudentGradeFromEcg(
                evidencePeriodData.evidence_column_groups,
                sr.student.id!
              ),
          }))
          .filter((s) => s.grade === selectedGradeKey && s.teachers!.some((teacher) => teacher.schools?.includes(selectedSchool!)))
          .groupBy((s) => s.gender)
          .mapValues((arr) => arr.map((s) => s.id!))
          .value()
      : {};
  }, [evidencePeriodData, selectedGradeKey, selectedSchool]);

  const freeLunchDictionary = useMemo(() => {
    return evidencePeriodData
      ? _.chain(evidencePeriodData.student_rows)
          .map((sr) => ({
            ...sr.student,
            grade:
              sr.student?.grade ||
              sr.grade ||
              getStudentGradeFromEcg(
                evidencePeriodData.evidence_column_groups,
                sr.student.id!
              ),
          }))
          .filter((s) => (s.grade === selectedGradeKey) && (s.free_or_reduced_lunch === true) && s.teachers!.some((teacher) => teacher.schools?.includes(selectedSchool!)))
          .groupBy((s) => s.free_or_reduced_lunch)
          .mapValues((arr) => arr.map((s) => s.id!))
          .value()
      : {};
  }, [evidencePeriodData, selectedGradeKey, selectedSchool]);

  const currentTimePeriod = useSelector<
    ApplicationState,
    TimePeriod | undefined
  >((s) => s.onboarding.currentDataPeriod?.time_period);

  const stats = useMemo(() => {
    if (!evidenceColumnGroup || !currentTimePeriod) {
      return {};
    }

    
    return _.chain(evidenceColumnGroup.evidence_columns)
      .keyBy((ec) => ec.id)
      .mapValues((ec) => {

        const gradeSpecificColorCriteria =
        ec.measurement != null ?  ec.measurement.grade_specific_color_criteria?.[selectedGradeKey]?.[
            currentTimePeriod
          ] : [];

        if (!gradeSpecificColorCriteria) {
          return null;
        }
        if(gradeSpecificColorCriteria == undefined){
          return null;
        }

        if(gradeSpecificColorCriteria == null){
          return null;
        }
        if(gradeSpecificColorCriteria?.length == 0){
          return null;
        }
        if(!gradeSpecificColorCriteria?.length){
          return null;
        }

        return _.chain(ec.student_entries)
          .reduce<{
            belowColor?: string;
            atOrAboveColor?: string;
            below: number[];
            atOrAbove: number[];
          }>(
            (pV, cV) => {
              const value = cV.value !== undefined ? +cV.value : undefined;
              if (!value && value !== 0) {
                return pV;
              }
              const colorCriteria = gradeSpecificColorCriteria.filter((cc) => cc != null).find(
                (cc) => value >= +cc.range_min! && value <= +cc.range_max!
              );
              if (!colorCriteria || !colorCriteria.benchmark_status) {
                return pV;
              }

              return colorCriteria.benchmark_status ===
                BenchmarkStatus.AtOrAbove
                ? {
                    ...pV,
                    atOrAbove: [...pV.atOrAbove, cV.student_id],
                  }
                : {
                    ...pV,
                    below: [...pV.below, cV.student_id],
                  };
            },
            {
              belowColor: gradeSpecificColorCriteria.filter((cc) => cc != null).find(
                (cc) =>
                  cc.benchmark_status === BenchmarkStatus.Below ||
                  BenchmarkStatus.WellBelow
              )?.color,
              atOrAboveColor: gradeSpecificColorCriteria.filter((cc) => cc != null).find(
                (cc) => cc && cc.benchmark_status === BenchmarkStatus.AtOrAbove
              )?.color,
              below: [],
              atOrAbove: [],
            }
          )
          .value();
      })
      .value();
  }, [evidenceColumnGroup, selectedGradeKey, selectedSchool, currentTimePeriod]);

  const allStudents = _.chain(raceDictionary)
    .values()
    .reduce<number[]>((pV, cV) => [...pV, ...cV], [])
    .value();

  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [allStudentsHeight, setAllStudentsHeight] = useState<number>(0);

  const benchmarkRef = useRef<HTMLDivElement | null>(null);
  const { reportGenerate } = usePDFGenerate();

  const handleReportGenerate = () => {
    const fileName:string = `Equity Benchmarks.pdf`;
    benchmarkRef.current?.classList.remove('equity-benchmark-table-max-height-wrapper');
    document.getElementById('equity-benchmark-table')!.style.display = 'inline-flex';
    reportGenerate(benchmarkRef, fileName, 'equityBenchmarks');
    setTimeout(() => {
      document.getElementById('equity-benchmark-table')!.style.display = 'none';
      benchmarkRef.current?.classList.add('equity-benchmark-table-max-height-wrapper');
     },1)
    
    return true;
  };

  return (
    <>
      {evidenceColumnGroup && (
        <h2 className="text-center">{evidenceColumnGroup.assessment != null ? evidenceColumnGroup.assessment.name : ''}</h2>
      )}
      <div className="row my-2 pt-2">
        <div className="col-3 d-flex mb-2 align-items-center">
          <label className="font-weight-bold mr-2">Grade: </label>
            <Select
              className="dataFiltersSelect w-100"
              value={selectedGrade}
              options={GradeLevels}
              onChange={handleGradeLevelChange}
              />
        </div>
        <div className="col-9 d-flex mb-2  align-items-center">
          <label className="font-weight-bold mr-2">School: </label>
          <Select
            className="dataFiltersSelect w-100"
            value={schoolOptions.find((school) => school.value == selectedSchool)}
            options={schoolOptions}
            onChange={handleSchoolChange}
          />
        </div>
      </div>


      {evidenceColumnGroup && (evidenceColumnGroup.assessment != null) && (evidenceColumnGroup.assessment.name !== 'SRSS') 
      ?
        !evidenceColumnGroup.assessment.is_system_assessment && (
          <h4 className="text-center">No equity benchmarks</h4>
        ) 
      :
      <h4 className="text-center">No equity benchmarks</h4>
      }
      {evidenceColumnGroup && (evidenceColumnGroup.assessment != null) &&
        evidenceColumnGroup.assessment.is_system_assessment && 
        (evidenceColumnGroup.assessment.name !== 'SRSS') && (
          <>
            <br/>
            <button
              className="float-right whiteBtnSm mb-2"
              onClick={handleReportGenerate}
            >
            Generate PDF
            </button>
            <div className="equity-benchmark-table-max-height-wrapper" ref={benchmarkRef}>
              <div className="row mb-2" id="equity-benchmark-table" style={{display: 'none', width: '100%'}}>
                <div className="col-7">
                  <div className="col-12"><h3 className=" pdfFontFamily"><span className="font-weight-bold">Title:</span> Equity Benchmarks ({evidenceColumnGroup.assessment != null ? evidenceColumnGroup.assessment.name : ''})</h3></div>
                  <div className="col-12"><h3 className=" pdfFontFamily"><span className="font-weight-bold">Data Period:</span> {dataPeriod?.name}</h3></div>
                </div>
                <div className="col-5">
                  {selectedGrade ?  <div className="col-12"><h3 className=" pdfFontFamily"><span className="font-weight-bold">Grade:</span> {selectedGrade.label}</h3></div> : ''}
                  {selectedSchool ?  <div className="col-12"><h3 className=" pdfFontFamily"><span className="font-weight-bold">School:</span> {schoolOptions.find((school) => school.value == selectedSchool).label}</h3></div> : ''}
                </div>
              </div>
              <div className="row mb-2">
                <div
                  style={{ cursor: "default"}}
                  className="d-flex col-auto"
                >
                  <div
                    style={{
                      background: "rgb(255, 209, 218)",
                      cursor: "inherit",
                    }}
                    className="sketchColorPickerColor col-auto"
                  /> 
                  <div className="font-weight-bold ml-2 pdfFontFamily">Well Below/Below</div>
                </div> 
                <div
                  style={{ cursor: "default"}}
                  className="d-flex col-auto"
                >
                  <div
                    style={{
                      background: "rgb(191, 249, 221)",
                      cursor: "inherit",
                    }}
                    className="sketchColorPickerColor col-auto"
                  /> 
                  <div className="font-weight-bold ml-2 pdfFontFamily">Average/Above</div>
                </div> 
              </div>
              <Table bordered responsive id="equity-benchmark-table-row" className="mb-0 custom-table-class"  >
                <thead>
                  <tr
                    className="text-center sticky-tr"
                    ref={(el) => {
                      setHeaderHeight(el?.clientHeight ?? 0);
                    }}
                  >
                    <th />
                    {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                      <th key={ec.id}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="fullName" className="customInfoTooltip">
                              {ec.measurement.display_name}
                            </Tooltip>
                          }
                        >
                          <span>{ec.measurement.column_name}</span>
                        </OverlayTrigger>
                      </th>
                    ))}
                  </tr>
                  <tr
                    className="text-center sticky-tr"
                    ref={(el) => {
                      setAllStudentsHeight(el?.clientHeight ?? 0);
                    }}
                  >
                    <td rowSpan={2} style={{ top: headerHeight }}>
                      All Students
                    </td>
                    {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => {
                      return (
                        <BellowCell
                          topOffset={headerHeight}
                          key={ec.id}
                          statsByEc={stats[ec.id]}
                          students={allStudents}
                        />
                      );
                    })}
                  </tr>
                  <tr className="text-center sticky-tr">
                    {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => {
                      return (
                        <AtOrAboveCell
                          topOffset={headerHeight + allStudentsHeight}
                          key={ec.id}
                          statsByEc={stats[ec.id]}
                          students={allStudents}
                        />
                      );
                    })}
                  </tr>
                </thead>
                {Object.entries(raceDictionary).map(([race, students]) => {
                  if(RaceDisplayedNames[+race as Race]) {
                    return (
                    <tbody key={`race_${race}`}>
                      <tr className="text-center">
                        <td rowSpan={2}>
                          {RaceDisplayedNames[+race as Race] ? `${RaceDisplayedNames[+race as Race]} (Race)` : "Unknown"}
                        </td>
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <BellowCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                      <tr className="text-center">
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <AtOrAboveCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                    </tbody>
                    )
                  }
                })}
                {Object.entries(specialEducationDictionary).map(([specialEducation, students]) => (
                  <tbody key={`spacial_education_${specialEducation}`}>
                      <tr className="text-center">
                        <td rowSpan={2}>
                          Special Education
                        </td>
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <BellowCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                      <tr className="text-center">
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <AtOrAboveCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                  </tbody>
                ))}
                {Object.entries(ELLDictionary).map(([ell, students]) => (
                  <tbody key={`ell_${ell}`}>
                      <tr className="text-center">
                        <td rowSpan={2}>
                          ELL (English Language Learner)
                        </td>
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <BellowCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                      <tr className="text-center">
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <AtOrAboveCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                  </tbody>
                ))}
                {Object.entries(SLDDictionary).map(([sld, students]) => (
                  <tbody key={`sld_${sld}`}>
                      <tr className="text-center">
                        <td rowSpan={2}>
                          SLD (Specific Learning Disability)
                        </td>
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <BellowCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                      <tr className="text-center">
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <AtOrAboveCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                  </tbody>
                ))}
                {Object.entries(genderDictionary).map(([gender, students]) => {
                  if(GenderDisplayedNames[gender as Gender]) {
                    return (
                      <tbody key={`gender_${gender}`}>
                        <tr className="text-center">
                          <td rowSpan={2}>
                            {`${GenderDisplayedNames[gender as Gender]} (Gender)` ?? "Unknown"}
                          </td>
                          {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                            <BellowCell
                              key={ec.id}
                              statsByEc={stats[ec.id]}
                              students={students}
                            />
                          ))}
                        </tr>
                        <tr className="text-center">
                          {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                            <AtOrAboveCell
                              key={ec.id}
                              statsByEc={stats[ec.id]}
                              students={students}
                            />
                          ))}
                        </tr>
                      </tbody>
                    )
                  }
                })}
                {Object.entries(freeLunchDictionary).map(([freeLunch, students]) => (
                  <tbody key={`freeLunch_${freeLunch}`}>
                      <tr className="text-center">
                        <td rowSpan={2}>
                        Free or Reduced Lunch
                        </td>
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <BellowCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                      <tr className="text-center">
                        {evidenceColumnGroup?.evidence_columns.filter((ec)=> !ec.measurement.display_name?.includes('Percentile')).map((ec) => (
                          <AtOrAboveCell
                            key={ec.id}
                            statsByEc={stats[ec.id]}
                            students={students}
                          />
                        ))}
                      </tr>
                  </tbody>
                ))}
              </Table>
            </div>
          </>
        )}
    </>
  );
};

export default EquityBenchmarksModalContent;
